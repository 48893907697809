<template>
  <el-card class="container">
    <el-descriptions class="margin-top" title="商品详情" :column="1">
      <el-descriptions-item label="商品属性">{{ detail.property == 1 ? '服务类' : '商品类' }}</el-descriptions-item>
      <el-descriptions-item label="对谁可见">
        <el-radio v-for="(item, index) in detail.scopes" :key="index">{{ item == 1 ? '企业采购商' : item == 2 ? '企业个人' : item == 3 ? '顾客' : '--' }}</el-radio>
      </el-descriptions-item>
      <el-descriptions-item label="所属店铺">{{ detail.shopName }}</el-descriptions-item>
      <el-descriptions-item label="商品名称">{{ detail.name }}</el-descriptions-item>
      <el-descriptions-item label="商品类别">{{ detail.goodsTypeName }}</el-descriptions-item>
      <el-descriptions-item label="售价">{{ detail.shopName }}</el-descriptions-item>
      <el-descriptions-item label="库存">{{ detail.inventoryCount }}</el-descriptions-item>
      <el-descriptions-item label="限购数量">{{ detail.limitCount == -1 ? '不限购' : detail.limitCount }}</el-descriptions-item>
      <el-descriptions-item label="已售数量">{{ detail.shopName }}</el-descriptions-item>
      <el-descriptions-item label="创建时间">{{ detail.createTime }}</el-descriptions-item>
      <el-descriptions-item label="更新时间">{{ detail.updateTime }}</el-descriptions-item>
      <el-descriptions-item label="状态">
        {{ detail.status == 0 ? '新增' : 
           detail.status == 1 ? '待审核' : 
           detail.status == 2 ? '审核未通过' : 
           detail.status == 3 ? '上架' : 
           detail.status == 4 ? '下架' : 
           '--' 
        }}
      </el-descriptions-item>
      <el-descriptions-item v-if="detail.status == 2" label="驳回理由">
        {{ detail.reason }}
      </el-descriptions-item>
      <el-descriptions-item label="库存">
        <el-table :data="detail.goodsSkus" style="width: 100%">
          <el-table-column prop="specification" label="规格">
          </el-table-column>
          <el-table-column prop="externalSkuNo" label="商品编码" width="400">
          </el-table-column>
          <el-table-column prop="skuNo" label="平台商品编码">
          </el-table-column>
          <el-table-column prop="saleAmount" label="售价">
          </el-table-column>
          <el-table-column prop="originalAmount" label="原价">
          </el-table-column>
          <el-table-column prop="inventoryCount" label="库存">
          </el-table-column>
          <el-table-column prop="specificationImage" label="规格图片">
            <template slot-scope="scope">
              <div class="img" style="width: 120px;height:120px;">
                <img style="width: 100%;height: 100%;" :src="scope.row.specificationImage" alt="">
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-descriptions-item>
      <el-descriptions-item label="封面">
        <div class="wrap">
          <div class="imgWrap" v-for="(item, index) in detail.covers" :key="index">
            <template>
              <div class="img">
                <img :src="item" alt="">
              </div>
            </template>
          </div>
        </div>
      </el-descriptions-item>
      <el-descriptions-item label="详情">
        <div v-html="detail.detail"></div>
      </el-descriptions-item>
    </el-descriptions>
    <div class="footer">
      <el-button class="btn" @click="$router.back()">返回</el-button>
    </div>
  </el-card>
</template>
<script>
export default {
  name: 'GoodsDetail',
  components: {
  },
  data () {
    return {
      detail: {}
    }
  },
  created () {
    this.handleInitDetail(this.$route.params.id)
  },
  methods: {
    async handleInitDetail (id) {
      const { data, code, msg } = await this.$api.shopManageApi.businessGoodsDetail({id})
      if (code == 200) {
        this.detail = data
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.container{
  width: 100%;
  overflow: auto;
  .wrap{
    display: flex;
    overflow: visible;
    .imgWrap{
      width: 100px;
      height: 100px;
      border: 1px solid #f1f1f1;
      position: relative;
      margin-right: 10px;
      overflow: visible;
      .img{
        width: 100px;
        height: 100px;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .footer{
    width: 100%;
    height: 100px;
    text-align: end;
    .btn{
      margin-right: 40px;
    }
  }
}
</style>
